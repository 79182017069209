import { SvgIcon } from '@mui/material';
import type { FC } from 'react';
import type { DeviceIconProps } from 'utils/helpers';

const GoeControllerIcon: FC<DeviceIconProps> = ({ color, ...rest }) => (
  <SvgIcon {...rest} viewBox="0 0 24 24" fill="none">
    <path
      d="M19 2H5C4.71667 2 4.47917 2.09583 4.2875 2.2875C4.09583 2.47917 4 2.71667 4 3C4 3.28333 4.09583 3.52083 4.2875 3.7125C4.47917 3.90417 4.71667 4 5 4H19C19.2833 4 19.5208 3.90417 19.7125 3.7125C19.9042 3.52083 20 3.28333 20 3C20 2.71667 19.9042 2.47917 19.7125 2.2875C19.5208 2.09583 19.2833 2 19 2Z"
      fill={color}
    />
    <path
      d="M19 20H5C4.71667 20 4.47917 20.0958 4.2875 20.2875C4.09583 20.4792 4 20.7167 4 21C4 21.2833 4.09583 21.5208 4.2875 21.7125C4.47917 21.9042 4.71667 22 5 22H19C19.2833 22 19.5208 21.9042 19.7125 21.7125C19.9042 21.5208 20 21.2833 20 21C20 20.7167 19.9042 20.4792 19.7125 20.2875C19.5208 20.0958 19.2833 20 19 20Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 19C3.45 19 2.97917 18.8042 2.5875 18.4125C2.19583 18.0208 2 17.55 2 17V7C2 6.45 2.19583 5.97917 2.5875 5.5875C2.97917 5.19583 3.45 5 4 5H20C20.55 5 21.0208 5.19583 21.4125 5.5875C21.8042 5.97917 22 6.45 22 7V17C22 17.55 21.8042 18.0208 21.4125 18.4125C21.0208 18.8042 20.55 19 20 19H4ZM6 9C6 9.28333 6.09583 9.52083 6.2875 9.7125C6.47917 9.90417 6.71667 10 7 10C7.28333 10 7.52083 9.90417 7.7125 9.7125C7.90417 9.52083 8 9.28333 8 9C8 8.71667 7.90417 8.47917 7.7125 8.2875C7.52083 8.09583 7.28333 8 7 8C6.71667 8 6.47917 8.09583 6.2875 8.2875C6.09583 8.47917 6 8.71667 6 9ZM6 12C6 12.2833 6.09583 12.5208 6.2875 12.7125C6.47917 12.9042 6.71667 13 7 13C7.28333 13 7.52083 12.9042 7.7125 12.7125C7.90417 12.5208 8 12.2833 8 12C8 11.7167 7.90417 11.4792 7.7125 11.2875C7.52083 11.0958 7.28333 11 7 11C6.71667 11 6.47917 11.0958 6.2875 11.2875C6.09583 11.4792 6 11.7167 6 12ZM6.2875 15.7125C6.09583 15.5208 6 15.2833 6 15C6 14.7167 6.09583 14.4792 6.2875 14.2875C6.47917 14.0958 6.71667 14 7 14C7.28333 14 7.52083 14.0958 7.7125 14.2875C7.90417 14.4792 8 14.7167 8 15C8 15.2833 7.90417 15.5208 7.7125 15.7125C7.52083 15.9042 7.28333 16 7 16C6.71667 16 6.47917 15.9042 6.2875 15.7125ZM18 9.00002L10 9L10 15H18L18 9.00002Z"
      fill={color}
    />
  </SvgIcon>
);

export default GoeControllerIcon;
