import * as Sentry from '@sentry/nextjs';
import getRandomString from 'utils/common/getRandomString';
import rsplit from 'utils/common/rsplit';
import { sha256hex } from 'utils/common/sha256hex';
import { v4 as uuidv4 } from 'uuid';

import type {
  MergedValidApiKey,
  ValidApiKey,
  ValidApiKeyTypes,
  MergedFullStatusReading,
} from 'redux-store/slices/api/types';
import type { PublicDatabaseDevice } from 'redux-store/slices/devices/types';
import type { DeviceSerial } from 'redux-store/slices/ui/types';

import type { CommonFullStatus } from './fullstatus';
import type {
  ApiErrorType,
  DeviceMessage,
  DeviceData,
  MessageQueue,
  WaitingApiKeys,
  WebSocketDeviceStates,
  WebSocketMessageType,
  ResponseMessage,
  WiFiConfig,
  WebSocketDeviceState,
  WaitingForUpdate,
} from './websocketTypes';
import {
  DeviceConnectionStage,
  DeviceStatus,
  PingMessage,
  WebSocketState,
} from './websocketTypes';

//export const _MAX_TIME_WITHOUT_MESSAGE = 30000; // 30 seconds
export const _MAX_TIME_WITHOUT_MESSAGE = 10000; // 10 seconds

export type LogType =
  | 'log'
  | 'warn'
  | 'error'
  | 'info'
  | 'extra1'
  | 'extra2'
  | 'debug';

export class GoeWebSocket {
  // websocket
  public __ws: WebSocket | null = null;

  public onopen: (() => void) | null = null;

  public onclose: (() => void) | null = null;

  public onerror: ((event: Event) => void) | null = null;

  private socketState: WebSocketState = WebSocketState.Disconnected;

  private readonly socketUrl: string | null = null;

  // message queue
  private messageQueue: MessageQueue = [];

  private waitingSetApiKeys: WaitingApiKeys = {};

  // reconnect
  private reconnectInterval: NodeJS.Timeout | null = null;

  private isReconnecting = false;

  // callbacks
  public ondeviceonline:
    | ((sse: string, reason: string, timeWhenStarted?: Date) => void)
    | null = null;

  public ondevicedata: ((sse: string, data: DeviceData) => void) | null = null;

  public ondeviceoffline: ((sse: string) => void) | null = null;

  public ondeviceerror:
    | ((sse: string, error: ApiErrorType, data: DeviceMessage) => void)
    | null = null;

  public onsetapikey:
    | ((sse: string, response: ResponseMessage) => void)
    | null = null;

  private emitDeviceOffline = true;

  // ping
  private pingInterval: NodeJS.Timeout | null = null;

  // state
  public __states: WebSocketDeviceStates = {};

  private waitingForUpdate: WaitingForUpdate[] = [];

  // debug logging
  private readonly debug_logging: boolean = false;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(url: string, debug_logging = false) {
    this.socketUrl = url;
    this.debug_logging = debug_logging;

    this.initWebSocket();
  }

  private log(type: LogType = 'log', ...args: unknown[]): void {
    const error = new Error();

    const [_function, _file, _line] = rsplit(
      error.stack?.split('\n')[1] ?? '',
      ':',
      2,
    );

    if (this.debug_logging) {
      let color = 'white';
      switch (type) {
        case 'log':
          color = 'white';
          break;
        case 'warn':
          color = 'orange';
          break;
        case 'error':
          color = 'red';
          break;
        case 'info':
          color = 'cyan';
          break;
        case 'debug':
          color = 'blue';
          break;
        case 'extra1':
          color = 'green';
          type = 'log';
          break;
        case 'extra2':
          color = 'purple';
          type = 'log';
          break;
      }

      if (args.some(a => typeof a === 'object')) {
        // eslint-disable-next-line no-console
        console[type](
          `%c[GoeWebSocket ${type} ${_function} (${_file}:${_line})]`,
          ...[`color: ${color}`],
          ...args,
        );
      } else {
        // eslint-disable-next-line no-console
        console[type](
          `%c[GoeWebSocket ${type} ${_function} (${_file}:${_line})] ${args.join(
            ' ',
          )}`,
          ...[`color: ${color}`],
        );
      }
    }
  }

  private sendJSON(data: unknown, from_queue = false): boolean {
    if (this.socketState === WebSocketState.Connected && this.__ws) {
      let _data: string | object = data as string | object;
      if (typeof _data === 'object') {
        _data = JSON.stringify(data);
      }

      try {
        this.__ws.send(_data);

        return true;
      } catch (e) {
        if (!from_queue) {
          this.messageQueue.push(data);
        }

        this.log('error', 'Failed to send', data, e);

        return false;
      }
    } else if (!from_queue) {
      this.messageQueue.push(data);
    }

    return false;
  }

  private initWebSocket(): void {
    this.log('info', 'Initializing websocket');

    if (!this.socketUrl) {
      throw new Error('No socket url');
    }

    if (this.socketState === WebSocketState.Connected) {
      this.log('info', 'Already connected');

      return;
    }

    this.isReconnecting = false;

    this.__ws = new WebSocket(this.socketUrl);

    this.__ws.onopen = (): void => {
      this.socketState = WebSocketState.Connected;

      if (this.onopen) this.onopen();
    };

    this.__ws.onclose = (): void => {
      this.socketState = WebSocketState.Disconnected;

      if (this.onclose) this.onclose();

      for (const sse in this.__states) {
        const state = this.__states[sse] as WebSocketDeviceState;
        state.full_status = {
          ...state.full_status,
          _status: DeviceStatus.Offline,
        };
        state.connection_state = DeviceConnectionStage.Offline;
        if (this.ondeviceoffline && this.emitDeviceOffline) {
          this.ondeviceoffline(sse);
        }

        if (this.ondevicedata) {
          this.ondevicedata(sse, {
            connection_state: state.connection_state,
            full_status: state.full_status,
            last_message: state.last_message,
          });
        }
        this.log('warn', `Device offline by websocket close: ${sse}`);
      }

      if (!this.isReconnecting) {
        this.reconnect();
      } else {
        this.log('info', 'Not reconnecting');
      }
    };

    this.__ws.onerror = (event: Event): void => {
      this.log('error', 'Websocket error', event, {
        isReconnecting: this.isReconnecting,
      });
      this.socketState = WebSocketState.Disconnected;

      if (this.onerror) this.onerror(event);

      if (!this.isReconnecting) {
        this.reconnect();
      }
    };

    this.__ws.onmessage = (event: MessageEvent): void => {
      let json = null;

      try {
        json = JSON.parse(event.data);
      } catch {
        return;
      }

      if (json === null) return;

      const { type } = json as { type: WebSocketMessageType };

      switch (type) {
        case 'connected': {
          this.socketState = WebSocketState.Connected;
          this.log('info', 'Connected');

          return;
        }

        case 'subscribe_success': {
          const { sse } = json as { sse: string };

          (this.__states[sse] as WebSocketDeviceState).connection_state =
            DeviceConnectionStage.Connecting;
          this.log('info', 'Subscribed to', sse);
          break;
        }

        case 'charger_message': {
          const { sse, data: ws_data } = json as {
            sse: string;
            data: DeviceMessage;
          };

          this.handleChargerMessage(sse, ws_data);
          break;
        }

        case 'error': {
          this.log(
            'error',
            'Server Error communicated through websocket:',
            json,
          );
          Sentry.captureMessage(`WebSocket error`, {
            contexts: {
              ws: {
                url: this.socketUrl,
                state: this.socketState,
              },
              response: {
                data: json,
              },
            },
            tags: {
              type: 'websocket',
            },
          });
          break;
        }

        case 'pong': {
          break;
        }

        case 'update_subscription_success': {
          break;
        }

        // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
        default: {
          this.log('warn', 'Unknown message type', type);
          break;
        }
      }
    };

    if (this.pingInterval) {
      clearInterval(this.pingInterval);
    }

    if (this.reconnectInterval) {
      clearInterval(this.reconnectInterval);
    }

    this.pingInterval = setInterval(() => {
      if (this.__ws && this.__ws.readyState === WebSocket.OPEN) {
        this.sendJSON(PingMessage);

        if (this.socketState === WebSocketState.Connected) {
          for (const message of this.messageQueue) {
            if (this.sendJSON(message)) {
              this.messageQueue.splice(this.messageQueue.indexOf(message), 1);
            }
          }
        }
      }

      for (const sse in this.__states) {
        const device = this.__states[sse];

        if (!device) continue;

        if (
          device.full_status._status === DeviceStatus.Online &&
          device.last_message
        ) {
          const now = new Date();
          const diff = now.getTime() - device.last_message.getTime();

          if (diff > _MAX_TIME_WITHOUT_MESSAGE) {
            // 10 seconds
            device.connection_state = DeviceConnectionStage.Offline;

            if (this.ondeviceoffline && this.emitDeviceOffline) {
              this.ondeviceoffline(sse);
            }

            this.log('warn', `Device offline by timeout (${diff}ms)`, sse);

            device.online_sent = false;
            device.offline_sent = true;
            device.timeWhenConnected = undefined;

            device.full_status = {
              ...device.full_status,
              _status: DeviceStatus.Offline,
            };
            if (this.ondevicedata) {
              this.ondevicedata(sse, {
                connection_state: device.connection_state,
                full_status: device.full_status,
                last_message: device.last_message,
              });
            }
          } else {
            this.log('debug', `Device online (${diff}ms)`, sse);
          }
        }
      }
    }, 1000);
  }

  private reconnect(): void {
    this.log('info', 'Reconnecting');
    this.isReconnecting = true;

    if (this.reconnectInterval) {
      this.log('info', 'interval exists, clearing');
      clearInterval(this.reconnectInterval);
    }

    this.reconnectInterval = setInterval(() => {
      this.log(
        'info',
        'socket',
        this.socketState,
        WebSocketState[this.socketState],
      );
      if (this.socketState === WebSocketState.Disconnected) {
        this.initWebSocket();
      }
    }, 1000);
  }

  // eslint-disable-next-line complexity
  private handleChargerMessage(sse: string, data: DeviceMessage): void {
    const device = this.__states[sse];

    if (!device) {
      this.log('warn', 'Unknown device', sse);

      return;
    }

    switch (data.type) {
      case 'authRequired':
        if (!device.passwordHash) {
          this.log('warn', 'No password hash for', sse);

          return;
        }

        const { token1, token2 } = data;
        const token3 = getRandomString(16);
        const hash = sha256hex(
          token3 + token2 + sha256hex(token1 + device.passwordHash),
        );

        this.sendAuth(sse, token3, hash);
        break;
      case 'authSuccess':
        device.connection_state = DeviceConnectionStage.Authenticated;
        break;
      case 'hello':
        device.connection_state = DeviceConnectionStage.HelloReceived;
        break;
      case 'fullStatus':
        device.connection_state = DeviceConnectionStage.Authenticated;

        if (this.ondeviceonline && !device.online_sent) {
          this.ondeviceonline(sse, 'fullStatus', device.connectionStart);
          device.timeWhenConnected = new Date();
          device.online_sent = true;
          device.offline_sent = false;

          this.log('info', 'Device online by fullStatus', sse);
        }

        const isLast = !data.partial;

        device.full_status = {
          ...data.status,
          _status: isLast ? DeviceStatus.Online : DeviceStatus.Loading,
        };

        device.last_message = new Date();

        if (this.ondevicedata) {
          this.ondevicedata(sse, {
            connection_state: device.connection_state,
            full_status: device.full_status,
            last_message: device.last_message,
          });
        }
        break;
      case 'deltaStatus':
        device.connection_state = DeviceConnectionStage.Authenticated;

        const { status } = data;

        // apply delta status
        for (const key in status) {
          device.full_status = {
            ...device.full_status,
            [key]: status[key as keyof CommonFullStatus],
          };
        }

        if (this.ondeviceonline && !device.online_sent) {
          this.ondeviceonline(sse, 'deltaStatus', device.connectionStart);
          device.timeWhenConnected = new Date();
          device.online_sent = true;
          device.offline_sent = false;

          this.log('info', 'Device online by deltaStatus', sse);
        }

        device.full_status = {
          ...device.full_status,
          _status: DeviceStatus.Online,
        };

        device.last_message = new Date();
        if (this.ondevicedata) {
          this.ondevicedata(sse, {
            connection_state: device.connection_state,
            full_status: device.full_status,
            last_message: device.last_message,
          });
        }
        break;
      case 'authError':
        device.connection_state = DeviceConnectionStage.IncorrectPassword;
        device.passwordHash = null;
        device.full_status = {
          ...device.full_status,
          _status: DeviceStatus.InvalidAuth,
        };

        if (this.ondeviceerror) {
          this.ondeviceerror(
            sse,
            { msg: data.message, error: 'authError' },
            data,
          );
        }

        if (this.ondevicedata) {
          this.ondevicedata(sse, {
            connection_state: device.connection_state,
            full_status: device.full_status,
            last_message: device.last_message,
          });
        }
        break;
      case 'response':
        const new_status = data.status;

        const waiting = this.waitingForUpdate.filter(
          e => e.serial === sse && e.requestId === data.requestId,
        );

        if (waiting.length > 0) {
          for (const wait of waiting) {
            wait.callback(data);
          }

          this.waitingForUpdate = this.waitingForUpdate.filter(
            e => e.serial !== sse || e.requestId !== data.requestId,
          );
        }

        if (!data.success && data.message) {
          if (this.ondeviceerror) {
            this.ondeviceerror(
              sse,
              {
                msg: data.message,
                error: 'response',
              },
              data,
            );
          }
          break;
        }

        for (const key in new_status) {
          device.full_status = {
            ...device.full_status,
            [key]: new_status[key as keyof CommonFullStatus],
          };
          if (
            this.waitingSetApiKeys[sse]
              .map(e => e.apiKey)
              .includes(key as ValidApiKey)
          ) {
            if (this.onsetapikey) this.onsetapikey(sse, data);
            this.waitingSetApiKeys[sse].splice(
              this.waitingSetApiKeys[sse].findIndex(
                e => e.apiKey === (key as ValidApiKey),
              ),
              1,
            );
          }
        }

        // clear waiting api keys based on requestId
        for (const serial in this.waitingSetApiKeys) {
          for (const key in this.waitingSetApiKeys[serial]) {
            if (
              this.waitingSetApiKeys[serial][key].requestId !== data.requestId
            ) {
              continue;
            }

            if (this.onsetapikey) this.onsetapikey(serial, data);

            this.waitingSetApiKeys[serial].splice(
              this.waitingSetApiKeys[serial].indexOf(
                this.waitingSetApiKeys[serial][key],
              ),
              1,
            );
          }
        }

        device.last_message = new Date();
        break;
      case 'offline':
        device.connection_state = DeviceConnectionStage.Offline;

        if (this.ondeviceoffline && !device.offline_sent) {
          this.ondeviceoffline(sse);
          device.offline_sent = true;
          device.online_sent = false;
          device.timeWhenConnected = undefined;

          this.log('warn', `Device offline by offline message: ${sse}`);
        }

        device.full_status = {
          ...device.full_status,
          _status: DeviceStatus.Offline,
        };

        if (this.ondevicedata) {
          this.ondevicedata(sse, {
            connection_state: device.connection_state,
            full_status: device.full_status,
            last_message: device.last_message,
          });
        }
        break;
      // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
      default: {
        this.log(
          'warn',
          'Unknown message type',
          (data as { type: never }).type,
        );
        break;
      }
    }
  }

  public updateSubscribers(
    subscribers: PublicDatabaseDevice[],
    force = false,
  ): boolean {
    if (this.socketState !== WebSocketState.Connected) {
      this.log('info', 'Not connected');

      return false;
    }

    const serial_numbers = subscribers.map(s => s.serial);
    const current_serial_numbers = Object.keys(this.__states);

    const passwordHashes = subscribers.map(s => s.passwordHash);
    const current_password_hashes = Object.values(this.__states).map(
      s => s?.passwordHash,
    );

    serial_numbers.sort();
    current_serial_numbers.sort();

    passwordHashes.sort();
    current_password_hashes.sort((a, b) => {
      if (!a) return -1;
      if (!b) return 1;

      return a.localeCompare(b);
    });

    if (
      JSON.stringify(serial_numbers) !==
        JSON.stringify(current_serial_numbers) ||
      JSON.stringify(passwordHashes) !==
        JSON.stringify(current_password_hashes) ||
      force
    ) {
      this.log('info', 'Changes to subscribers', force);
    } else {
      this.log('info', 'No changes to subscribers', force);

      return false;
    }

    for (const subscriber of subscribers) {
      const { serial } = subscriber;

      const changed_password_hash =
        this.__states[serial]?.passwordHash !== subscriber.passwordHash;
      const is_new = !this.__states[serial];

      if (is_new || changed_password_hash) {
        this.__states[serial] = {
          sse: serial,
          connection_state: DeviceConnectionStage.Connecting,
          last_message: new Date(),
          full_status: { _status: DeviceStatus.Loading },
          passwordHash: subscriber.passwordHash,
          connectionStart: new Date(),
        };
      } else {
        (this.__states[serial] as WebSocketDeviceState).passwordHash =
          subscriber.passwordHash;
      }

      if (changed_password_hash) {
        this.resubscribe(serial);
      }

      if (typeof this.waitingSetApiKeys[serial] === 'undefined') {
        this.waitingSetApiKeys[serial] = [];
      }
    }

    for (const serial in this.__states) {
      if (!serial_numbers.includes(serial)) {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete this.__states[serial];
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete this.waitingSetApiKeys[serial];
      }
    }

    return this.sendJSON({
      type: 'update_subscription',
      list: serial_numbers,
    });
  }

  private sendDeviceMessage(
    sse: string,
    message: string | object,
  ): { success: boolean } {
    if (this.socketState !== WebSocketState.Connected) {
      this.log('info', 'Not connected');

      return { success: false };
    }

    if (!this.__states[sse]) {
      this.log('info', 'No such subscriber', sse);

      return { success: false };
    }

    if (
      (this.__states[sse] as WebSocketDeviceState).connection_state <
      DeviceConnectionStage.Connecting
    ) {
      this.log('info', 'Not connected to subscriber', sse);

      return { success: false };
    }

    return {
      success: this.sendJSON({
        type: 'charger_message',
        sse,
        data: message,
      }),
    };
  }

  private sendAuth(
    serial: DeviceSerial,
    token3: string,
    hash: string,
  ): { success: boolean } {
    const requestId = uuidv4();

    return this.sendDeviceMessage(serial, {
      type: 'auth',
      token3,
      hash,
      requestId,
    });
  }

  private resubscribe(serial: DeviceSerial): void {
    if (this.socketState !== WebSocketState.Connected) {
      this.log('info', 'Not connected');

      return;
    }

    if (!this.__states[serial]) {
      this.log('info', 'No such subscriber', serial);

      return;
    }

    this.log('info', 'Resubscribing', serial);

    this.sendJSON({
      type: 'resubscribe',
      sse: serial,
    });
  }

  public close(emitDeviceOffline = true): void {
    this.isReconnecting = true;
    this.emitDeviceOffline = emitDeviceOffline;
    this.log('info', 'Closing connection', this.emitDeviceOffline);
    if (this.__ws) this.__ws.close();
  }

  public setApiKey(
    serial: DeviceSerial | undefined,
    api_key: MergedValidApiKey,
    value: ValidApiKeyTypes,
    sudo = false,
  ): { success: boolean; requestId?: string } {
    if (!serial) {
      return { success: false };
    }

    if (!this.__states[serial]) {
      this.log('error', 'No such subscriber', serial);

      return { success: false };
    }

    if (typeof this.waitingSetApiKeys[serial] === 'undefined') {
      this.waitingSetApiKeys[serial] = [];
    }

    const requestId = uuidv4();

    this.waitingSetApiKeys[serial].push({
      apiKey: api_key,
      requestId,
    });

    return {
      success: this.sendDeviceMessage(serial, {
        type: 'setValue',
        key: api_key,
        value,
        sudo: sudo ? 'servus' : undefined,
        requestId,
      }).success,
      requestId,
    };
  }

  private async waitForApiKey(
    serial: DeviceSerial,
    requestId: string,
  ): Promise<ResponseMessage> {
    return new Promise(resolve => {
      const callback = (data: ResponseMessage): void => {
        resolve(data);
      };

      const waitingUpdate: WaitingForUpdate = {
        serial,
        requestId,
        callback,
      };

      this.waitingForUpdate.push(waitingUpdate);
    });
  }

  public deleteWiFiConfig(
    serial: DeviceSerial | undefined,
    wifi_index: number,
  ): { success: boolean; requestId?: string } {
    if (!serial) {
      return { success: false };
    }

    if (!this.__states[serial]) {
      this.log('info', 'No such subscriber', serial);

      return { success: false };
    }

    return this.setApiKey(serial, 'delw', wifi_index);
  }

  public rescanWiFis(serial: DeviceSerial | undefined): { success: boolean } {
    if (!serial) {
      return { success: false };
    }

    const requestId = uuidv4();

    return this.sendDeviceMessage(serial, { type: 'wifiScan', requestId });
  }

  public scanControllers(serial: DeviceSerial | undefined): {
    success: boolean;
  } {
    if (!serial) {
      return { success: false };
    }

    const requestId = uuidv4();

    return this.sendDeviceMessage(serial, {
      type: 'requestControllerScan',
      requestId,
    });
  }

  public pairController(
    serial: DeviceSerial | undefined,
    controllerId: string,
  ): { success: boolean } {
    if (!serial) {
      return { success: false };
    }

    const requestId = uuidv4();

    return this.sendDeviceMessage(serial, {
      type: 'pairController',
      controllerId,
      requestId,
    });
  }

  public unpairController(
    serial: DeviceSerial | undefined,
    controllerId: string,
  ): { success: boolean } {
    if (!serial) {
      return { success: false };
    }

    const requestId = uuidv4();

    return this.sendDeviceMessage(serial, {
      type: 'unpairController',
      controllerId,
      requestId,
    });
  }

  public syncTime(serial: DeviceSerial | undefined): { success: boolean } {
    if (!serial) {
      return { success: false };
    }

    const requestId = uuidv4();

    return this.sendDeviceMessage(serial, {
      type: 'syncTime',
      requestId,
    });
  }

  public switchAppPartition(serial: DeviceSerial | undefined): {
    success: boolean;
  } {
    if (!serial) {
      return { success: false };
    }

    const requestId = uuidv4();

    return this.sendDeviceMessage(serial, {
      type: 'switchAppPartition',
      requestId,
    });
  }

  public otaCloud(
    serial: DeviceSerial | undefined,
    firmwareVersion: string,
  ): { success: boolean } {
    if (!serial) {
      return { success: false };
    }

    const requestId = uuidv4();

    return this.sendDeviceMessage(serial, {
      type: 'otaCloud',
      firmware: firmwareVersion,
      requestId,
    });
  }

  public _sendWiFiConfig(
    serial: DeviceSerial | undefined,
    data: { ssid: string; key: string },
  ): { success: boolean; error?: string; requestId?: string } {
    if (!serial) {
      return { success: false };
    }

    const current_wifi_configs =
      (this.__states[serial]?.full_status as MergedFullStatusReading).wifis ||
      [];
    const first_free_wifi_config_index = current_wifi_configs.findIndex(
      config => !config.key && !config.ssid,
    );

    if (first_free_wifi_config_index === -1) {
      this.log('info', 'Cannot add new wifi config, no free space');

      return { success: false, error: 'No free space' };
    }

    const config: WiFiConfig[] = [];

    for (let i = 0; i < first_free_wifi_config_index; i++) {
      config.push({} as WiFiConfig);
    }

    config.push(data as unknown as WiFiConfig);

    return this.setApiKey(serial, 'wifis', config);
  }

  public updateWiFiConfig(
    serial: DeviceSerial | undefined,
    wifi_index: number,
    data: Partial<WiFiConfig>,
  ): { success: boolean; error?: string; requestId?: string } {
    if (!serial) {
      return { success: false };
    }

    const config: WiFiConfig[] = [];

    for (let i = 0; i < wifi_index; i++) {
      config.push({} as WiFiConfig);
    }

    config.push({
      ...data,
    } as unknown as WiFiConfig);

    return this.setApiKey(serial, 'wifis', config);
  }

  configureWiFi(
    serial: DeviceSerial | undefined,
    ssid: string,
    password: string,
  ): { success: boolean; error?: string; requestId?: string } {
    return this._sendWiFiConfig(serial, {
      ssid,
      key: password,
    });
  }
}
