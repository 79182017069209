import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';
import type { FC } from 'react';

const DeviceGroupsIcon: FC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <mask
      id="mask0_15949_1063"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_15949_1063)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 7C4.46243 7 2 9.46243 2 12.5V16.625C2 19.6626 4.46243 22.125 7.5 22.125C10.5376 22.125 13 19.6626 13 16.625V12.5C13 9.46243 10.5376 7 7.5 7ZM11 16.625V12.5C11 10.567 9.433 9 7.5 9C5.567 9 4 10.567 4 12.5V16.625C4 18.558 5.567 20.125 7.5 20.125C9.433 20.125 11 18.558 11 16.625Z"
        fill={props.color}
      />
      <circle cx="7.5" cy="16.625" r="2.5" fill={props.color} />
      <path
        d="M14 16C14 16.5523 14.4477 17 15 17H20C20.55 17 21.0208 16.8042 21.4125 16.4125C21.8042 16.0208 22 15.55 22 15V6C22 5.45 21.8042 4.97917 21.4125 4.5875C21.0208 4.19583 20.55 4 20 4H14L12 2H6C5.45 2 4.97917 2.19583 4.5875 2.5875C4.19583 2.97917 4 3.45 4 4V5C4 5.55228 4.44772 6 5 6C5.55228 6 6 5.55228 6 5V4H11.175L13.175 6H20V15H15C14.4477 15 14 15.4477 14 16Z"
        fill={props.color}
      />
    </g>
  </SvgIcon>
);

export default DeviceGroupsIcon;
