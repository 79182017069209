import { useTheme } from '@mui/material';
import type { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { default as MuiButton } from '@mui/material/Button';
import type { FC } from 'react';
import { useMemo } from 'react';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    rounded: true;
    'rounded-outlined': true;
    flat: true;
  }

  interface ButtonPropsColorOverrides {
    white: true;
    black: true;
    neutral: true;
    grey: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    white: true;
    black: true;
    neutral: true;
    grey: true;
    grey100: true;
    grey200: true;
    grey300: true;
    grey400: true;
    grey500: true;
    grey600: true;
    grey700: true;
    grey800: true;
    grey900: true;
  }
}

type ButtonProps = {
  radius?: number | string;
} & MuiButtonProps;

const Button: FC<ButtonProps> = ({ radius, color, ...props }) => {
  const theme = useTheme();

  const computedVariant = useMemo(() => {
    let variant = props.variant;

    if (!variant) {
      variant = 'flat';
    }

    if (
      variant === 'flat' ||
      variant === 'rounded' ||
      variant === 'rounded-outlined'
    ) {
      variant = 'contained';
    }

    return variant;
  }, [props.variant]);

  const computedSx = useMemo(() => {
    let sx = props.sx;

    switch (color) {
      case 'white':
        sx = {
          ...sx,
          color: theme.vars.palette.text.primary,
          backgroundColor: theme.vars.palette.common.white,
        };
        break;
      case 'black':
        sx = {
          ...sx,
          color: theme.vars.palette.common.white,
          backgroundColor: theme.vars.palette.grey[900],
        };
        break;
      case 'neutral':
        sx = {
          ...sx,
          color: theme.vars.palette.primary.dark,
          backgroundColor: theme.vars.palette.grey[300],
          '&:hover': {
            backgroundColor: theme.vars.palette.grey[200],
          },
          '&. MuiTouchRipple-root': {
            backgroundColor: theme.vars.palette.grey[200],
          },
        };
        break;
      default:
        break;
    }

    if (
      radius ||
      props.variant === 'rounded' ||
      props.variant === 'rounded-outlined'
    ) {
      sx = {
        ...sx,
        borderRadius: radius ?? 1.6,
      };
    }

    return sx;
  }, [color, props.sx, props.variant, radius, theme]);

  const actualColor = ['white', 'black', 'neutral', 'grey'].includes(
    color ?? '',
  )
    ? undefined
    : color;

  //@ts-ignore: TS2769 because custom variant
  return (
    <MuiButton
      {...props}
      color={actualColor}
      sx={computedSx}
      variant={computedVariant}
      disableElevation={props.variant === 'flat'}
    />
  );
};

export default Button;
