import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';
import type { FC } from 'react';

const RfidIcon: FC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox="0 0 24 24" fill="none">
    <mask
      id="mask0_9880_3813"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <path
      d="M15.2125 16H8.8125C8.6125 16 8.4375 15.925 8.2875 15.775C8.1375 15.625 8.0625 15.4333 8.0625 15.2C8.0625 14.9167 8.15417 14.6417 8.3375 14.375C8.52083 14.1083 8.77917 13.875 9.1125 13.675C9.47917 13.4417 9.90417 13.2708 10.3875 13.1625C10.8708 13.0542 11.4125 13 12.0125 13C12.6125 13 13.1542 13.0542 13.6375 13.1625C14.1208 13.2708 14.5458 13.4417 14.9125 13.675C15.2458 13.875 15.5 14.1083 15.675 14.375C15.85 14.6417 15.9375 14.9167 15.9375 15.2C15.9375 15.4333 15.8667 15.625 15.725 15.775C15.5833 15.925 15.4125 16 15.2125 16Z"
      fill={props.fill}
    />
    <path
      d="M13.425 11.4125C13.0333 11.8042 12.5625 12 12.0125 12C11.4625 12 10.9917 11.8042 10.6 11.4125C10.2083 11.0208 10.0125 10.55 10.0125 10C10.0125 9.45 10.2083 8.97917 10.6 8.5875C10.9917 8.19583 11.4625 8 12.0125 8C12.5625 8 13.0333 8.19583 13.425 8.5875C13.8167 8.97917 14.0125 9.45 14.0125 10C14.0125 10.55 13.8167 11.0208 13.425 11.4125Z"
      fill={props.fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12V4C2 2.89543 2.89543 2 4 2H12C17.5228 2 22 6.47715 22 12ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
      fill={props.fill}
    />
  </SvgIcon>
);

export default RfidIcon;
