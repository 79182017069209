/* eslint-disable react/jsx-no-useless-fragment, @typescript-eslint/ban-ts-comment */
// noinspection AllyJsxHardcodedStringInspection
// @ts-nocheck
import { GitHub, CloudQueue } from '@mui/icons-material';
import { Box, Divider, Tab, Tabs, Typography } from '@mui/material';
import { useWebsocket } from 'provider/WebsocketApiProvider';
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { _MAX_TIME_WITHOUT_MESSAGE } from 'utils/communication/goewebsocket';
import {
  DeviceConnectionStage,
  DeviceStatus,
} from 'utils/communication/websocketTypes';

import GoeChargerPhoenixIcon from 'components/Icons/DeviceIcons/GoeChargerPhoenixIcon';
import GoeChargerPhoenixOutlinedIcon from 'components/Icons/DeviceIcons/GoeChargerPhoenixOutlinedIcon';
import GoeChargerV3Icon from 'components/Icons/DeviceIcons/GoeChargerV3Icon';
import GoeChargerV3OutlinedIcon from 'components/Icons/DeviceIcons/GoeChargerV3OutlinedIcon';
import GoeChargerV4Icon from 'components/Icons/DeviceIcons/GoeChargerV4Icon';
import GoeChargerV4OutlinedIcon from 'components/Icons/DeviceIcons/GoeChargerV4OutlinedIcon';
import GoeChargerV5Icon from 'components/Icons/DeviceIcons/GoeChargerV5Icon';
import GoeChargerV5OutlinedIcon from 'components/Icons/DeviceIcons/GoeChargerV5OutlinedIcon';
import GoeControllerIcon from 'components/Icons/DeviceIcons/GoeControllerIcon';
import GoeControllerOutlinedIcon from 'components/Icons/DeviceIcons/GoeControllerOutlinedIcon';
import GoeControllerUltralightIcon from 'components/Icons/DeviceIcons/GoeControllerUltralightIcon';
import GoeControllerUltralightOutlinedIcon from 'components/Icons/DeviceIcons/GoeControllerUltralightOutlinedIcon';

import { useAppSelector } from 'redux-store';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: FC<TabPanelProps> = props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ width: '100%' }}
      {...other}
    >
      {value === index ? <>{children}</> : null}
    </div>
  );
};

enum readyStateMap {
  'CONNECTING',
  'OPEN',
  'CLOSING',
  'CLOSED',
}

const formatTime = (millis: number): string => {
  if (millis > 100000) return '∞';
  if (millis > 10000) return `${(millis / 1000).toFixed(2)}s`;

  return `${millis}ms`;
};

const colorTime = (millis: number): string => {
  const percent = Math.min(1, millis / _MAX_TIME_WITHOUT_MESSAGE);

  const r = Math.round(255 * percent);
  const g = 0;
  const b = 0;

  return `rgb(${r}, ${g}, ${b})`;
};

interface Props {
  hideForever?: () => void;
}

const DebugModal: FC<Props> = ({ hideForever }) => {
  const [tab, setTab] = useState(0);
  const [timeout, configureTimeout] = useState(750);
  const [counter, setCounter] = useState(0);

  const ws = useWebsocket();
  const ws__states = ws?.__states;

  const [showFullStatus, setShowFullStatus] = useState<string | null>(null);

  const fullStatus = useAppSelector(state =>
    showFullStatus ? state.api.fullStatus[showFullStatus] : null,
  );

  // rerender ws__states every 500ms
  useEffect(() => {
    const interval = setInterval(() => {
      if (tab === 1 && ws !== null) setCounter(counter + 1);
    }, timeout);

    return () => clearInterval(interval);
  }, [tab, counter, ws, timeout]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="100%"
      flex={1}
    >
      <Typography variant="h5">
        Debug Modal <a onClick={hideForever}>(Hide)</a>
      </Typography>
      <Divider />
      <TabPanel index={0} value={tab}>
        {process.env.NODE_ENV === 'development' ? (
          <>
            <Typography variant="body2">
              Git Branch: {process.env.GIT_BRANCH}
            </Typography>
            <Typography variant="body2">
              Git Commit: {process.env.GIT_COMMIT_SHA_SHORT}
            </Typography>
            <Typography variant="body2">
              Git Tag: {process.env.GIT_TAG}
            </Typography>
          </>
        ) : null}
        <Typography variant="body2">
          NODE_ENV: {process.env.NODE_ENV}
        </Typography>
        <button
          type="button"
          onClick={() => {
            throw new Error('Test Error');
          }}
        >
          Test Error
        </button>
      </TabPanel>
      <TabPanel index={1} value={tab}>
        {showFullStatus !== null ? (
          <>
            <pre
              style={{
                maxHeight: 500,
                overflow: 'auto',
                fontSize: '0.75rem',
              }}
            >
              {JSON.stringify(fullStatus, null, 2)}
            </pre>
            <button type="button" onClick={() => setShowFullStatus(null)}>
              Close
            </button>
          </>
        ) : (
          <>
            {ws !== null && ws__states ? (
              <>
                {ws.__ws !== null ? (
                  <>
                    <Typography variant="body2">
                      ws.readyState: {ws.__ws.readyState} (
                      {readyStateMap[ws.__ws.readyState]})
                    </Typography>
                    <Typography variant="body2">
                      ws.url: {ws.__ws.url}
                    </Typography>
                  </>
                ) : (
                  <Typography variant="body2">ws.__ws is 'null'</Typography>
                )}
                <Divider />
                <Box maxHeight={500} overflow="auto">
                  <table style={{ fontSize: '0.75rem' }}>
                    <thead>
                      <tr>
                        <th>serial_number</th>
                        <th>connection_state</th>
                        <th>last_message</th>
                        <th>_status</th>
                        <th>time to connected</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(ws__states).map(
                        ([serial_number, value]) => {
                          // @ts-ignore: TS2362 because subtracting two dates
                          const diff = new Date() - value?.last_message;

                          return (
                            <tr key={serial_number}>
                              <td
                                onClick={() => setShowFullStatus(serial_number)}
                              >
                                {serial_number}
                              </td>
                              <td>
                                {
                                  DeviceConnectionStage[
                                    value?.connection_state ?? 0
                                  ]
                                }
                              </td>
                              <td style={{ color: colorTime(diff) }}>
                                {formatTime(diff)} ago
                              </td>
                              <td>
                                {value?.full_status._status
                                  ? DeviceStatus[value.full_status._status]
                                  : 'null'}
                              </td>
                              <td>
                                {typeof value?.timeWhenConnected !==
                                  'undefined' &&
                                typeof value.connectionStart !== 'undefined'
                                  ? formatTime(
                                      value.timeWhenConnected -
                                        value.connectionStart,
                                    )
                                  : 'null'}
                              </td>
                            </tr>
                          );
                        },
                      )}
                    </tbody>
                  </table>
                </Box>
                <input
                  type="range"
                  min={50}
                  max={1000}
                  defaultValue={timeout}
                  // @ts-ignore: TS2339 because e.target.value works
                  onMouseUp={e =>
                    configureTimeout(parseInt(e.target.value, 10))
                  }
                  style={{ width: '100%' }}
                />
                <Typography variant="body2">
                  Refresh every {timeout}ms
                </Typography>
              </>
            ) : (
              <Typography variant="body2">ws is 'null'</Typography>
            )}
          </>
        )}
      </TabPanel>
      <TabPanel index={2} value={tab}>
        <Typography variant="body2">Tab 3</Typography>
        <Box display="flex" flexDirection="row" gap={1}>
          <Typography variant="body2">GoeChargerV3Icon</Typography>
          <GoeChargerV3Icon />
        </Box>
        <Box display="flex" flexDirection="row" gap={1}>
          <Typography variant="body2">GoeChargerV3OutlinedIcon</Typography>
          <GoeChargerV3OutlinedIcon />
        </Box>
        <Box display="flex" flexDirection="row" gap={1}>
          <Typography variant="body2">GoeChargerV4Icon</Typography>
          <GoeChargerV4Icon />
        </Box>
        <Box display="flex" flexDirection="row" gap={1}>
          <Typography variant="body2">GoeChargerV4OutlinedIcon</Typography>
          <GoeChargerV4OutlinedIcon />
        </Box>
        <Box display="flex" flexDirection="row" gap={1}>
          <Typography variant="body2">GoeChargerV5Icon</Typography>
          <GoeChargerV5Icon />
        </Box>
        <Box display="flex" flexDirection="row" gap={1}>
          <Typography variant="body2">GoeChargerV5OutlinedIcon</Typography>
          <GoeChargerV5OutlinedIcon />
        </Box>
        <Box display="flex" flexDirection="row" gap={1}>
          <Typography variant="body2">GoeChargerPhoenixIcon</Typography>
          <GoeChargerPhoenixIcon />
        </Box>
        <Box display="flex" flexDirection="row" gap={1}>
          <Typography variant="body2">GoeChargerPhoenixOutlinedIcon</Typography>
          <GoeChargerPhoenixOutlinedIcon />
        </Box>
        <Box display="flex" flexDirection="row" gap={1}>
          <Typography variant="body2">GoeControllerIcon</Typography>
          <GoeControllerIcon />
        </Box>
        <Box display="flex" flexDirection="row" gap={1}>
          <Typography variant="body2">GoeControllerOutlinedIcon</Typography>
          <GoeControllerOutlinedIcon />
        </Box>
        <Box display="flex" flexDirection="row" gap={1}>
          <Typography variant="body2">GoeControllerUltralightIcon</Typography>
          <GoeControllerUltralightIcon />
        </Box>
        <Box display="flex" flexDirection="row" gap={1}>
          <Typography variant="body2">
            GoeControllerUltralightOutlinedIcon
          </Typography>
          <GoeControllerUltralightOutlinedIcon />
        </Box>
      </TabPanel>
      <Divider />
      <Tabs value={tab} onChange={(_, value) => setTab(value)}>
        <Tab value={0} label="Git" icon={<GitHub />} />
        <Tab value={1} label="WebSocket" icon={<CloudQueue />} />
        <Tab value={2} label="Icons" icon={<GoeChargerV3Icon />} />
      </Tabs>
    </Box>
  );
};

export default DebugModal;
