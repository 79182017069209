globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"evcms-private-customers@110e4ce57028843454830a489d67036b00e27b07"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: 'https://9f7ec9a65ae51b3afa5aaa41cb81dd70@sentry.go-e.io/10',
  environment: process.env.BUILD_ENV,
  enabled:
    process.env.NODE_ENV === 'production' ||
    process.env.FORCE_SENTRY === 'true',

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: process.env.NODE_ENV === 'development' ? 1 : 0.2,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug:
    process.env.NODE_ENV === 'development' &&
    process.env.DISABLE_SENTRY_DEBUG !== 'true',

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    Sentry.breadcrumbsIntegration({
      console: process.env.NODE_ENV === 'production',
    }),
    Sentry.browserTracingIntegration(),
  ],
});
