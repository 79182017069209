import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';
import type { FC } from 'react';

const RfidsIcon: FC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <mask
      id="mask0_15949_14473"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <path d="M0 0H24V24H0V0Z" fill={props.fill} />
    </mask>
    <g mask="url(#mask0_15949_14473)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 20C18.4183 20 22 16.4183 22 12C22 7.58172 18.4183 4 14 4L8 4C6.89543 4 6 4.89543 6 6L6 12C6 16.4183 9.58172 20 14 20ZM14 6C10.6863 6 8 8.68629 8 12C8 15.3137 10.6863 18 14 18C17.3137 18 20 15.3137 20 12C20 8.68629 17.3137 6 14 6ZM6.10873 18.1437C4.78725 16.4485 4 14.3165 4 12.0006L4 5.00063C4 4.44834 3.55228 4.00063 3 4.00063C2.44771 4.00063 2 4.44834 2 5.00063L2 12.0006C2 14.8286 3.46736 17.3138 5.68223 18.7365C6.05092 18.9734 6.37814 18.4893 6.10873 18.1437Z"
        fill={props.fill}
      />
    </g>
  </SvgIcon>
);

export default RfidsIcon;
