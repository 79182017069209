import { useMemo } from 'react';
import type { FC } from 'react';
import type { GoeDeviceType } from 'utils/communication/websocketTypes';
import { DeviceIconMap, DeviceOutLinedIconMap } from 'utils/helpers';
import type { KnownGoeDeviceType, DeviceIconProps } from 'utils/helpers';

export interface DeviceIconComponentProps extends DeviceIconProps {
  typ?: GoeDeviceType;
  outlined?: boolean;
}

const DeviceIconComponent: FC<DeviceIconComponentProps> = ({
  typ,
  outlined,
}) => {
  const Icon = useMemo(() => {
    if (typeof typ !== 'undefined') {
      if (outlined && typ in DeviceOutLinedIconMap) {
        return DeviceOutLinedIconMap[typ as KnownGoeDeviceType];
      } else if (typ in DeviceIconMap) {
        return DeviceIconMap[typ as KnownGoeDeviceType];
      }
    }

    return null;
  }, [typ, outlined]);

  return Icon ? <Icon data-testid={`${typ}-icon`} /> : null;
};

export default DeviceIconComponent;
