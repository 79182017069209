import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';
import type { FC } from 'react';

const RfidGroupsIcon: FC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <mask
      id="mask0_15949_4369"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_15949_4369)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.18182 9C2.52912 9 2 9.52912 2 10.1818V15.5C2 19.0899 4.91015 22 8.5 22C12.0899 22 15 19.0899 15 15.5C15 11.9101 12.0899 9 8.5 9H3.18182ZM8.5 20C10.9853 20 13 17.9853 13 15.5C13 13.0147 10.9853 11 8.5 11C6.01472 11 4 13.0147 4 15.5C4 17.9853 6.01472 20 8.5 20Z"
        fill={props.color}
      />
      <path
        d="M16 16C16 16.5523 16.4477 17 17 17H20C20.55 17 21.0208 16.8042 21.4125 16.4125C21.8042 16.0208 22 15.55 22 15V6C22 5.45 21.8042 4.97917 21.4125 4.5875C21.0208 4.19583 20.55 4 20 4H14L12 2H6C5.45 2 4.97917 2.19583 4.5875 2.5875C4.19583 2.97917 4 3.45 4 4V7C4 7.55228 4.44772 8 5 8C5.55228 8 6 7.55228 6 7V4H11.175L13.175 6H20V15H17C16.4477 15 16 15.4477 16 16Z"
        fill={props.color}
      />
    </g>
  </SvgIcon>
);

export default RfidGroupsIcon;
